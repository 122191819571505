import { Input, Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate  } from "react-router-dom";
import { useLocation  } from "react-router-dom";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import Header from '../Header';
import Top from '../../pages/Top';
import { LeftCircleOutlined, } from '@ant-design/icons';

const WalletReceive = () =>  {
    const location = useLocation();
    const navigate = useNavigate();
    const string = useSelector(state => state.stringReducer.string);    
    const [priceInputShow, setPriceInputShow] = useState(false);
    const [priceOk, setPriceOk] = useState(false);
    const [price, setPrice] = useState('');
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    });    

    useEffect(()=>{
        getList();
    },[pagination.current]);

    // const handleTableChange = (pagination) => {
    //     const pager = { ...pagination };
    //     pager.current = pagination.current;
    //     setPagination({
    //         pagination: pager
    //     })      
    // };

    const getList = () => {}

    const copy = () => {
        const text = document.createElement("textarea")
        document.body.appendChild(text)
        text.value = location.state.data.address
        text.select();
        document.execCommand('copy');
        document.body.removeChild(text);
        Modal.info({
            content: string.copyComplete
        })
    }
    // const inputOk = (price) => {
    //     setPrice(price)     
    // }
    return (
        <div className="wrapper">
            <Top />
            <div className="container">
                <Header />
                <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                    <div className="main-box">
                        <div className="main-title">
                            <div className="back-btn" onClick={()=>navigate(-1)}>
                                <LeftCircleOutlined style={{ fontSize: 24, color:'#fff' }} />
                            </div> 
                            {/* <div className="main-text">{string.address}</div> */}
                            {priceInputShow && !priceOk ?
                                <div className='walletBtn'>
                                    <div className="more-btn2">
                                        <Button
                                            className="moreBtn"
                                            onClick={() => 
                                                setPriceOk(true)
                                            }
                                        >{string.ok}
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            className="moreBtn2"
                                            onClick={() =>
                                                setPriceInputShow(false)
                                            }
                                        >{string.cancel}
                                        </Button>
                                    </div>
                                </div>
                                :
                                <>
                                    {/* <div className="more-btn2">
                                        <Button
                                            style={{ borderRadius: 10, width: 100, height: 40, color: 'gray', fontWeight: 'bold' }}
                                            onClick={() => this.setState({
                                                priceInputShow: true,
                                                priceInputOk: true,
                                                priceOk: false,
                                            })}
                                        >{string.priceInsert}
                                        </Button>
                                    </div> */}
                                    <div>
                                        <Button
                                            className="moreBtn"
                                            onClick={copy}
                                        >{string.copyAddress}
                                        </Button>
                                    </div>
                                </>


                            }
                        </div>
                    </div>
                    <div className="charge-inner dialog">
                        <QRCode
                            // value={this.state.sele == '' ? this.props.address : this.props.address + "?value=" + this.state.price + "&coinType=" + this.props.coin.symbol}
                            size={200}
                            color='#000'
                            value={location.state.data.address}
                        />
                        <div className="address-block">
                            <div className="content-box address-box">{location.state.data.address}</div>

                        </div>
                        {priceInputShow &&
                            <div style={{ display: "inline-block" }}>{string.qrContainPrice}:
                                {priceOk ?
                                    <div style={{ display: "inline-block", marginLeft: 20 }}>{price}</div>
                                    :
                                    <Input style={{ width: 200, marginLeft: 20 }}
                                        value={price}
                                        onChange={(e) => 
                                            setPrice(e.target.value)
                                        } />
                                }
                                <span style={{ marginLeft: 10 }}>{location.state.data.symbol}</span>
                            </div>
                        }

                    </div>
                </div>

            </div>

        </div>
    )
}

export default WalletReceive;