import React, { Component, useState } from "react";
import { Modal, Descriptions, Input } from "antd";
import { render } from "less"; 
import { httpUrl, httpPost } from "../../api/httpClient";

const Ditems = Descriptions.Item;

const InquiryDetailModal = (props) => {
    const [title, setTitle] = useState('')
    const onCancel = () => {
        props.onCancel();
    };
  const { visible, data, onClose } = props;
  return (
    <Modal
        visible={visible}
        title={"문의등록"}
        onOk={()=>{
            httpPost(httpUrl.inquiryCreate, [], {
                userIdx:data.idx,
                title: title
            }).then((res) =>{
                if(res.data === "SUCCESS"){
                    Modal.info({
                        title: "등록",
                        content: "문의 내용이 등록 되었습니다."
                    })
                    onClose()
                } else {
                    Modal.error({
                        title: "오류",
                        content: "오류가 발생했습니다. 관리자에게 문의해주세요."
                    });
                }
            })
        }}
        onCancel={onClose}        
        okText={"등록"}
        cancelText="취소"  
        style={{ minWidth: 'auto'}}
    >
        <Descriptions
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        >            
            <Ditems label="작성자" span={2} labelStyle={{textAlign: "center"}}>
               {data.id}
            </Ditems>
            <Ditems label="문의내용" span={2} labelStyle={{textAlign: "center"}}>
               <Input.TextArea
               rows={4}
               value={title}
               onChange={(e) => {
                 setTitle(e.target.value)  
            }}

               />
            </Ditems>
  
        </Descriptions>
    </Modal>
);
}

export default InquiryDetailModal;