import { Input, Button, Modal } from 'antd';
import React, { useRef, useState } from 'react';
import { httpUrl, httpPost } from "../api/httpClient";
import { stringReducer,} from "../actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MailOutlined, SmileOutlined } from '@ant-design/icons';

const ModifyPassword = () => {
    const formRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);    
    const [email, setEmail] = useState('');
    const [step, setStep] = useState(0);

    const handleSubmit = async () => {      
        console.log()
        const res = await httpPost(httpUrl.userFindPassword, [email], {});
        if (!res.data) {
            Modal.error({
                title: string.findId,
                content: string.mnemonicError,
            });          
        } else {
           setStep(1);
        };           
    }

    return (
        <div className="wrapper">
           <div className="BT-HD-right-container">
                <img src={require('../img/logo/logo.png').default} style={{width: 160}}/> 
                <div style={{marginLeft: 16}}>
                    <div className={`BT-HD-right-element ${language == 'ko' ? 'color-blue' : ''}`} onClick={() => dispatch(stringReducer('ko'))}>
                        KO
                    </div>
                    <div className={`BT-HD-right-element ${language == 'en' ? 'color-blue' : ''}`} onClick={() => dispatch(stringReducer('en'))}>
                        EN
                    </div>
                    <div className={`BT-HD-right-element ${language == 'ja' ? 'color-blue' : ''}`} onClick={() => dispatch(stringReducer('ja'))}>
                        JA
                    </div>
                </div>
            </div>  
            <div className="container-login-box" style={{ textAlign: 'center' }}>               
                {step === 0 ?   
                <>  
                <div style={{ fontSize: 16, color: '#fff', wordBreak:'keep-all', textAlign:'center', marginBottom: 20 }}>
                    {string.findPasswordDescription}
                </div>
                <div className="form login">                    
                    <div className='formbox'>
                        <div className="login-field-icon">
                            <MailOutlined />
                        </div>
                        <div className="login-field-input">                          
                            <Input className="input22"
                                autoComplete='false'
                                placeholder={string.idEmail}
                                style={{margin: 0}}
                                value={email}
                                onChange={(e)=>setEmail(e.target.value)}
                            />                          
                        </div>
                    </div>
                </div>
                <div className="signupbtn" onClick={handleSubmit} style={{marginTop: -40}}>                       
                    <Button className="signup-btn-ok">{string.ok}</Button>                      
                </div> 
                </> 
                :    
                <div className='findPwResult'>
                    <SmileOutlined className='findPwResult-icon'/>
                    <p>{string.findPasswordEmailSentSuccess}</p>
                    <Button className="signup-btn-ok" onClick={() => navigate('/')}>{string.login}</Button>
                </div>             
                }
              
            </div>            
        </div>
    )
}
export default ModifyPassword;