export default {
    serverProtocol: 'https',
    serverIp: 'www.tlabwallet.com',
  
    // serverProtocol: 'http',
    // serverIp: "localhost",  
    serverPort: '18001',
    serverContext: '',
      
    enableForeignExchangeRate: false,
  
    appCode: 'Tlabwallet'
  };
  