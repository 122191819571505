
import React, { useEffect } from 'react';
import { httpGet, httpUrl,} from "../api/httpClient";
import { useLocation, useNavigate } from "react-router-dom";
import con from '../const';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(()=>{
        requestCurrencyExchangeRate();
    },[]);

    const requestCurrencyExchangeRate = (hide) => {
        if (con.enableForeignExchangeRate) {
            console.log("##### Request currencyExchangeRate")
            return new Promise((resolve, reject) => {
                httpGet(httpUrl.currencyExchangeRate, [], {}, hide).then((result) => {
                    console.log("## Request currencyExchangeRate loaded")
                    result = result.data;
                    global.exchangeRate = [
                        1,
                        result.find(x => x.currency === 'KRWUSD').rate,
                        result.find(x => x.currency === 'KRWCNY').rate,
                        result.find(x => x.currency === 'KRWJPY').rate,
                    ]
                    resolve();
                }).catch(e => {
                    console.log("## requestCurrencyExchangeRate error: " + e)
                    reject();
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                global.exchangeRate = [1, 1, 1, 1];
                resolve();
            })
        }
    }

    const menus = [
        {
            idx: 1,
            name: " 시세",
            url: "/Currency",
        },
        {
            idx: 2,
            name: "내지갑",
            url: "/MyWallet",
        },
        {
            idx: 3,
            name: "교환",
            url: "/Exchange",
        },
        {
            idx: 4,
            name: "채굴",
            url: "/Mining",
        },
        {
            idx: 5,
            name: "공지사항",
            url: "/notice",
            parent: "/Currency",

        },
        {
            idx: 6,
            name: "지갑상세",
            url: "/myWalletDialog",
            parent: "/MyWallet",

        },
        {
            idx: 7,
            name: "지갑받기",
            url: "/walletReceive",
            parent: "/MyWallet",

        },
        {
            idx: 8,
            name: "지갑보내기",
            url: "/walletSend",
            parent: "/MyWallet",

        },
        {
            idx: 9,
            name: "1:1문의",
            url: "/inquiry",
            parent: "/mypage",

        },
        {
            idx: 10,
            name: "내정보",
            url: "/mypage",               
        },
        
    ];
    const menuShow = menus.filter(x => x.idx <= 2 || x.idx > 9)
    const currentPage = menus.find(
        (x) => x.url === location.pathname
    );
    // console.log(this.props.userinfo)

    return (

        <div className="header-container">
            <div className="top-navi">
                <div>
                    {menuShow.map((row) => {
                        return (
                            <div
                                key={row.idx}
                                onClick={() => navigate(row.url)}
                                className={"top-menu " + (row.idx === currentPage.idx || row.url === currentPage.parent ? "active" : "")}
                            >
                                <div className="top-text">   {row.name}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>

    )
}


export default Header;