import { Input, Checkbox, Button, Modal,} from 'antd';
import React, { useEffect, useState } from 'react';
import { reactLocalStorage } from "reactjs-localstorage";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import crypto, { } from 'crypto';
import { useNavigate, useLocation } from "react-router-dom";
import string from '../../language/StringKo';
import Header from '../../components/Header';
import Coin from '../../lib/coin/coin';
import Top from '../../pages/Top';
import { LeftCircleOutlined, } from '@ant-design/icons';
import { useSelector } from "react-redux";
import Const from '../../const'

const WalletSend = () => {
    const location = useLocation();
    const navigate = useNavigate();    
    const coinList = useSelector(state => state.coinListReducer);   
    const userinfo = reactLocalStorage.getObject(Const.appCode + "#userinfo");    
    const [sendAmount, setSendAmount] = useState(0);
    const [targetAddress, setTargetAddress] = useState('');
    const [checkConfirm, setCheckConfirm] = useState(false);
    const [destinationTag, setDestinationTag] = useState('');
    const [secPassword, setSecPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [memo, setMemo] = useState('');
    const [selectedCoinType, setSelectedCoinType] = useState(location.state.data.coinType);
    const [sendConfirmVisible, setSendConfirmVisible] = useState(false);
    const [sendInfo, setSendInfo] = useState({});
    const [sendTarget, setSendTarget] = useState({});


    useEffect(()=>{
        initData();
    },[]);

    const initData = () => {
        setSendAmount(0)
        setTargetAddress('')
        setCheckConfirm(false)
        setDestinationTag('')
        setSecPassword('')

        setOtp('')
        setMemo('')
        setSelectedCoinType(location.state.data.coinType)
        setSendConfirmVisible(false)

        setSendInfo({})
        setSendTarget({})
    };

    const send = () => {
        // if(!this.state.address){
        //     Modal.info({
        //         content: string.pleaseInsertAddress
        //     })
        //     return;
        // }
        // if(!this.state.amount){
        //     Modal.info({
        //         content: string.pleaseInsertAmount
        //     })
        //     return;
        // }
        // if(!this.state.securityPw){
        //     Modal.info({
        //         content: string.pleaseInsertSecurityPw
        //     })
        //     return;
        // }
        // if(!this.state.otp){
        //     Modal.info({
        //         content: string.pleaseInsertOtp
        //     })
        //     return;
        // }
    }

    const onClickSendCoin = async (values) => {
        console.log('## send coin selected cointype')
        console.log(sendAmount)
        let coin = coinList.find(x => x.coinType === selectedCoinType);

        if (targetAddress.length <= 0) {
            Modal.info({ content: string.sendCoinInputAddress })
            return;
        }

        if (sendAmount === '' || sendAmount <= 0) {
            Modal.info({ content: string.sendCoinInputAmount })
            return;
        }

        if (Math.floor(sendAmount * Coin.decimalPlace) / Coin.decimalPlace <= 0) {
            Modal.info({ content: string.sendCoinInputAmountLow })
            return;
        }

        if (secPassword.length < 4) {
            Modal.info({ content: string.sendCoinFailedNoSecPassword })
            return;
        }

        if (userinfo.securityLevel > 1 && otp.length < 6) {
            Modal.info({ content: string.inputOtpValidation })
            return;
        }

        if (!checkConfirm) {
            Modal.info({ content: string.sendCoinNeedConfirm })
            return;
        }
        // 수수료 계산
        let result = await httpGet(httpUrl.walletCheckFee, [selectedCoinType, targetAddress])
        console.log(JSON.stringify(result));
        // result = result.data;

        let fee;

        if (result.result !== 'SUCCESS') {
            Modal.info({ content: string.feeRequestFailed })
            return;
        }
        fee = result.data / Coin.getCoinUnit(selectedCoinType).base;

        console.log("## fee: " + fee);

        if (coin.balance < fee) {
            Modal.info({
                content: string.sendCoinLowFee + " (" + fee + " " + coin.symbol + ")"
            })
            return;
        }

        // 코인 전송 시작

        console.log("##### Sending coin");
        let sendamount = parseFloat(sendAmount);
        let sendAmountBase = Math.round(sendamount * Coin.decimalPlace) / Coin.decimalPlace * Coin.getCoinUnit(coin.coinType).base;
        let secPasswordHash = crypto.createHash('sha256').update(secPassword + userinfo.id, 'utf8').digest().toString('hex');

        let target = {
            amount: sendAmountBase,
            coinType: coin.coinType,
            to: targetAddress,
            destinationTag: destinationTag,
            otpCode: otp,
            securityPassword: secPasswordHash,
            memo: memo,
            fee: fee,
        };

        console.log('## send target: ' + JSON.stringify(target));

        confirmSendCoin(coin, target);
    }

    const confirmSendCoin = (coin, target) => {

        setSendConfirmVisible(true);
        setSendInfo({
            coinName: coin.name,
            coinSymbol: coin.symbol,
            address: target.to + (coin.coinType === 3 ? '(Tag) '+ target.destinationTag: ''),
            amount: Math.round(target.amount / Coin.getCoinUnit(selectedCoinType).base * Coin.decimalPlace) / Coin.decimalPlace,
            fee: target.fee,
            feeUnit: coin.symbol,
            destinationTag: target.destinationTag,
        })
        setSendTarget(target)
      
    }

    const sendCoin = async () => {
        let result = await httpPost(httpUrl.walletSend, [], sendTarget);

        console.log(JSON.stringify(result));
        result = result.data;

        if (result === 'SUCCESS' || result === 'APPROVAL') {
            console.log("## sent success");
            Modal.info({
                title: string.sendCoinSuccessTitle,
                content: (
                    <div>
                        {string.sendCoinSuccess}
                    </div>
                ),
                onOk() { },
            });
            initData();
            return;
        } else {
            let message = string.sendCoinFailed;
            if (result === 'INVALID_ADDRESS') message = string.sendCoinFailedInvalidAddress;
            else if (result === 'INSUFFICIENT_BALANCE') message = string.sendCoinFailedInsufficientBalance;
            else if (result === 'LOCKED') message = string.sendCoinFailedLocked + "\n" + string.sendCoinFailedDetail;
            else if (result === 'INVALID_OTP') message = string.sendCoinFailedInvalidOtp;
            else if (result === 'INVALID_SECURITY_PASSWORD') message = string.sendCoinFailedInvalidSecPassword;
            else if (result === 'REQUIRED_OTP') message = string.sendCoinFailedOtpRequired;
            else if (result === 'LIMIT_EXCEED') message = string.sendCoinFailedLimitExceed;
            else if (result === 'INVALID_DESTINATIONTAG') message = string.sendCoinFailedInvalidDesTag;
            else if (result === 'REQUIRED_OTP_DAILY') message = string.sendCoinFailedOtpRequired;
            else if (result === 'CANNOT_SEND_ORGANIZATION') message = string.sendCoinFailedOrganization;
            else if (result === 'INVALID_SECURITY_LEVEL') message = string.sendCoinFailedSecurityLevelRequired;
            else if (result === 'KYC_RESTRICTION_DAY') message = string.sendCoinFailedKycDay;

            Modal.info({
                title: string.sendCoinFailedTitle,
                content: (
                    <div>
                        {message}
                    </div>
                ),
                onOk() { },
            });
        }
    };

    return (
        <div className="wrapper">
            <Top />
            <div className="container">
                <Header />
                <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                    <div className="main-title" style={{ texAlign: "left" }}>
                        <div className="back-btn" onClick={()=>navigate(-1)}>
                            <LeftCircleOutlined style={{ fontSize: 24, color:'#fff' }} />
                        </div>
                    </div>                 
                    <div className="charge-inner">
                        <div className="charge-inner2">                            
                            현재 잔액                       
                            <span style={{ color: "#e86259", marginRight: 5, marginLeft: 16, fontWeight:'bold' }}>{location.state.data.balance}</span>
                            {location.state.data.symbol}                            
                        </div>                       
                        <p>{string.depositAddress}</p>
                        <Input
                            style={{ borderRadius: 20, padding: 15, marginBottom: 18, marginTop:18 }}
                            placeholder={string.insertAddress}
                            onChange={(e) => setTargetAddress(e.target.value)}
                            value={targetAddress}
                        />    
                        <p>{string.insertAmount}</p>               
                        <Input
                            style={{ borderRadius: 20, padding: 15, marginBottom: 18, marginTop:18 }}
                            placeholder={string.insertAmount}
                            onChange={(e) => {setSendAmount(e.target.value) }}
                            value={sendAmount}
                        />
                        {selectedCoinType === 3 && (
                        <Input
                            style={{ borderRadius: 20, padding: 15, marginBottom: 18, marginTop:18 }}
                            placeholder={string.inputDestinationTag}
                            onChange={(e) => setDestinationTag(e.target.value)}
                            value={destinationTag}
                        />   
                        )}
                        <Input
                            style={{ borderRadius: 20, padding: 15, marginBottom: 18 }}
                            placeholder={string.insertMemo}
                            onChange={(e) => setMemo(e.target.value)}
                            value={memo}
                        />
                        <Input.Password
                            style={{ borderRadius: 20, padding: 15}}
                            placeholder={string.securityPw}
                            onChange={(e) => setSecPassword(e.target.value)}
                            value={secPassword}
                        />
                        {userinfo.securityLevel >= 2 && (
                            <Input
                                style={{borderRadius: 20, padding: 15, marginTop: 18 }}
                                placeholder='OTP'
                                onChange={(e) => setOtp(e.target.value)}
                                value={otp}
                            />
                        )}
                        <div className="contentBlock">
                            <div
                                className="content-box"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",   
                                    alignItems:'center',
                                    marginTop: 24                 
                                }}
                            >
                                <div style={{color:'#fff', marginRight: 16}}>{string.sendCoinCautionConfirm}</div>
                                <Checkbox
                                    leftTextStyle={{
                                        fontSize: 15.7,
                                        letterSpacing: 0.05,
                                        color: '#000',
                                        lineHeight: 20,                                       
                                    }}
                                    onClick={() => {setCheckConfirm(!checkConfirm)}}
                                    isChecked={checkConfirm}
                                    checkBoxColor="#e1e1e1"
                                />
                            </div>
                            <div className="content-box"
                            style={{ fontWeight: "normal", fontSize: 16, textAlign: "left", color:'#fff', wordBreak:'keep-all' }}>
                                {string.sendCoinCautionConfirmDescriptionEx.join(" ")}
                            </div>
                            <Button className="signup-btn-ok" onClick={onClickSendCoin} style={{border:'none', color:"#fff"}}>
                                {string.walletSend}                           
                            </Button>
                        </div>
                    </div>
                </div>

            </div>

            {sendConfirmVisible && (
                <Modal className="trade-send-confirm-modal" visible={sendConfirmVisible} onOk={sendCoin} onCancel={() => setSendConfirmVisible(false)} cancelText={string.close} okText={string.ok}>
                    <div className="trade-send-confirm-title">{string.sendCoinConfirmDlgTtl}</div>
                    <div>{string.sendCoinConfirmMessage}</div>
                    <div className="trade-send-confirm-item">{string.address}:</div>
                    <div className="trade-send-confirm-item" style={{ marginLeft: '10px', color: '#693cff' }}>{sendInfo.address}</div><br />
                    <div className="trade-send-confirm-item">{string.value}:</div>
                    <div className="trade-send-confirm-item" style={{ marginLeft: '10px' }}>{sendInfo.amount}</div>
                    <div className="trade-send-confirm-item">{sendInfo.coinSymbol}</div><br />
                    <div className="trade-send-confirm-item">{string.fee}:</div>
                    {sendInfo.fee === 0 ? (
                        <div className="trade-send-confirm-item" style={{ marginLeft: '10px' }}>{string.exemption}</div>
                    ) : (
                        <div className="trade-send-confirm-item" style={{ marginLeft: '10px' }}>
                            <div className="trade-send-confirm-item">{sendInfo.fee}</div>
                            <div className="trade-send-confirm-item">{sendInfo.feeUnit}</div>
                        </div>
                    )}
                </Modal>
            )}
        </div>
    )
}

export default WalletSend;