
import React from 'react';
import { useSelector } from "react-redux";

const Privacy = () => {
    const string = useSelector(state => state.stringReducer.string);
    return (
        <div>
            <div className="agreement-content-title">
                {string.policyTitle}
            </div>
                
            <div>
                {string.policyContent.map((value, index) => {
                    let result = [];
                    if (value.title)
                        result.push(
                        <div key={'title_' + index} className="agreement-content-title">
                            {value.title}
                        </div>
                        );
                    if (value.content)
                        result.push(
                        <div key={'content_' + index} className="agreement-content-body">
                            {value.content}
                        </div>
                        );
                    return result;
                })}
            </div>
        </div>
    )
}

export default Privacy;