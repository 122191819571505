import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React, { useState, useRef } from 'react';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto from 'crypto';
import { stringReducer } from "../actions";
import { Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SmileOutlined, LockOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons';


const SignUp = (props) => {
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);
    const [emailDuplCheck, setEmailDuplCheck] = useState(false);
    const [certiVerified, setCertiVerified] = useState(false);
    const [phoneEditable, setPhoneEditable] = useState(true);
    const [timer, setTimer] = useState(180);
    const [onTimer, setOnTimer] = useState(false);
    const [visible, setVisible] = useState(false);
    const [recommendCode, setRecommendCode] = useState(props.match ? props.match.params.recommendCode : '');
    const [resendEmailVisible, setResendEmailVisible] = useState(false);
    const [resendEmailAddress, setResendEmailAddress] = useState('');
    const [resendEmailError, setResendEmailError] = useState('');
    const [email, setEmail] = useState();
    let certiCodeToken = '';

    const onPressEmailDuplCheck = () => {
        if (emailDuplCheck) return;       
        httpGet(httpUrl.userCheckEmail, [email]).then((result) => {
            console.log("## onPressEmailDuplCheck result: " + JSON.stringify(result));
            result = result.data;
            if (result == "SUCCESS") {
                formRef.current.setFields([{
                    name: 'email',
                    value: email,
                    errors: []
                }]);
                setEmailDuplCheck(true);
            } else {
                console.log("## onPressEmailDuplCheck error");
                let message = string.emailDuplCheckError;
                if (result == 'INVALID_EMAIL') message = string.emailDuplCheckErrorInvalidEmail;
                if (result == 'ALREADY_EXIST') message = string.emailDuplCheckErrorEmailDupl;
                formRef.current.setFields([{
                    name: 'email',
                    value: email,
                    errors: [message]
                }]);
            }
        }).catch(e => {
            console.log("## userPhoneauthCheck error: " + e)
        });

    }

    const onPressSendCertiCode = () => {
        const form = formRef.current;
        const formPhone = form.getFieldValue("phone").replace(/[^0-9]+/g,'');
        form.setFields([{ name: 'phone', value: formPhone }]);

        if (certiVerified) return;
        if (onTimer) return;

        if (formPhone == undefined || formPhone == '' || formPhone.length < 9) {
            form.setFields([{
                name: 'phone',
                errors: [string.signUpRuleMsg08]
            }]);
            return;
        }
        httpPost(httpUrl.userPhoneauthGenerate, [], { phone: formPhone }).then((result) => {
            console.log("## userPhoneauthGenerate result: " + JSON.stringify(result));
            result = result.data.token;
            certiCodeToken = result;
            if (result && result != '') {
                setOnTimer(true);
                setPhoneEditable(false)
                let interval = setInterval(
                    () => {
                        if (timer == 0) {
                            clearInterval(interval);
                            setOnTimer(false);
                            setTimer(180);
                            return;
                        }
                        setTimer(timer - 1);
                    },
                    1000
                );
            } else {
                form.setFields([{
                    name: 'phone',
                    errors: [string.certiCodeSendFail]
                }]);
                console.log("## userPhoneauthGenerate server error");
            }
        }).catch(e => {
            console.log("## userPhoneauthGenerate error: " + e)
        });
    }

    const onPressCheckCertiCode = () => {
        if (certiVerified) return;
        if (!onTimer) return;

        const form = formRef.current;
        const formPhone = form.getFieldValue("phone").replace(/[^0-9]+/g,'');
        const formCertiCode = form.getFieldValue("certiCode");

        if (formCertiCode == undefined || formCertiCode == '') {
            form.setFields([{
                name: 'certiCode',
                errors: [string.certiCodeFail]
            }]);
            return;
        };

        httpGet(httpUrl.userPhoneauthCheck, [certiCodeToken, formCertiCode], {}).then((result) => {
            console.log("## userPhoneauthCheck result: " + JSON.stringify(result));
            result = result.data;

            if (result.responseCode == "SUCCESS") {
                setCertiVerified(true);
            } else {
                console.log("## userPhoneauthCheck server error");
                let message = string.certiCodeWrong;
                if (result.responseCode == 'EXPIRED') message = string.certiCodeExpired;
                form.setFields([{
                    name: 'certiCode',
                    errors: [message]
                }]);
            }
        }).catch(e => {
            console.log("## userPhoneauthCheck error: " + e)
        });
    };

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    };

    const handleSubmit = (values) => {
        const form = formRef.current;

        let password = form.getFieldValue("password") + form.getFieldValue("email");
        let secPassword = form.getFieldValue("securityPassword") + form.getFieldValue("email");
    
        let passwordHash = crypto
        .createHash('sha256')
        .update(password, 'utf8')
        .digest()
        .toString('hex');
        
        let secPasswordHash = crypto
        .createHash('sha256')
        .update(secPassword, 'utf8')
        .digest()
        .toString('hex');

        httpPost(httpUrl.signUp, [], {
            deviceId: 'web',
            id: form.getFieldValue("email"),
            mnemonic: 'web',
            password: passwordHash,
            name: form.getFieldValue("name"),
            phone: form.getFieldValue("phone"),
            recommender: recommendCode,
            securityPassword: secPasswordHash,
    
        }).then((result)=>{
            console.log("## signUp result: " + JSON.stringify(result));
            result = result.data;      
            
            if (result.result){
                navigate('/signupok');
            } else {
                let message = string.signUpErrorMessage;
                if(result.reason == 'DUPLICATE') message = string.signUpErrorIdDuplication;
                else if(result.reason == 'WRONG_EMAIL_TYPE') message = string.signUpErrorInvalidEmailType;
                else if(result.reason == 'MAX_PHONE') message = string.signUpErrorMaxPhone;
                
                Modal.info({
                    title: string.signUpFailTitle,
                    content: (
                    <div className="signup-result">
                        {message}
                    </div>
                    ),
                    onOk() {},
                });
            }
        
        }).catch(e=>{
        console.log ("## signUp error: " + e)
        });
    }

    const agreementModal = () => {
        Modal.info({
          width: 500,
          title: string.policyTitle,
          content: (
            <div className="agreement-content">
                {string.policyContent.map((value, index) => {
                    let result = [];
                    if (value.title)
                        result.push(
                        <div key={'title_' + index} className="agreement-content-title">
                            {value.title}
                        </div>
                        );
                    if (value.content)
                        result.push(
                        <div key={'content_' + index} className="agreement-content-body">
                            {value.content}
                        </div>
                        );
                    return result;
                })}
            </div>
          ),
          onOk() {},
        });
      }

    const resendEmail = () => {        
        if (resendEmailAddress == null) {
            setResendEmailError(string.emailRule03)
            return;
        }
        let resendEmailAddress = resendEmailAddress.trim()
        if (resendEmailAddress == '') {
            setResendEmailError(string.emailRule03)
            return;
        }
        var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        if (!regExp.test(resendEmailAddress)) {
            setResendEmailError(string.emailRule03)
            return;
        }
        httpGet(httpUrl.userResendEmailSignup, [resendEmailAddress]).then((result)=>{
            result = result.data;
            if (result == "SUCCESS"){
                setResendEmailVisible(false);
                Modal.info({
                    content: (
                    <div className="signup-result">
                        {string.emailResent}
                    </div>
                    ),
                    onOk() {},
                });
            } else {
                let message = string.emailResendFailed;
                if (result == "INVALID_EMAIL") message = string.invalidEmail;
                if (result == "ALREADY_VERIFIED") message = string.emailAlreadyVerified;
                setResendEmailVisible(false);
                Modal.info({
                    content: (
                    <div className="signup-result">
                         {message}
                    </div>
                    ),
                    onOk() {},
                });              
            }
      
        }).catch(e=>{
            console.log ("## resendEmail error: " + e);
        });
    }
    return (
        <div className="wrapper">
            <div className="BT-HD-right-container">
                <img src={require('../img/logo/logo.png').default} style={{width: 160}}/> 
                <div style={{marginLeft: 16}}>
                    <div className={`BT-HD-right-element ${language=='ko' ? 'color-blue' : ''}`} onClick={()=> dispatch(stringReducer('ko'))}>
                        KO
                    </div>
                    <div className={`BT-HD-right-element ${language=='en' ? 'color-blue' : ''}`} onClick={()=>dispatch(stringReducer('en'))}>
                        EN
                    </div>
                    <div className={`BT-HD-right-element ${language=='ja' ? 'color-blue' : ''}`} onClick={()=>dispatch(stringReducer('ja'))}>
                        JA
                    </div>
                </div>
            </div>            
            <div className="container-login-box">
            <div className="header">
                {string.welcome}
            </div>                
                                
                    <Form onFinish={handleSubmit} className="form" ref={formRef}>                     
                        <div className='formbox'>
                            <div className="login-field-icon">
                            <SmileOutlined/>
                            </div>                           
                            <div className="login-field-input">
                                <Form.Item
                                    name="email"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                console.log(value)
                                                if (!value || value === '') return Promise.reject(string.signUpRuleMsg09);
                                                else if (!emailDuplCheck) return Promise.reject(string.needEmailDuplCheck);
                                                else return Promise.resolve();
                                            },
                                        }),                                   
                                    ]}
                                >
                                    <Input className="input22"
                                        placeholder={string.idEmail}
                                        disabled={emailDuplCheck}
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value.trim())}
                                        style={{margin: 0}}                  
                                    />
                                     <div className="overlap-button" onClick={onPressEmailDuplCheck}>
                                        {emailDuplCheck ? string.completed : string.dupCheck}
                                    </div>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='formbox'>
                            <div className="login-field-icon">
                            <LockOutlined />
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: string.signUpRuleMsg10
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                let strongRegex = new RegExp(
                                                    '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$'
                                                );
                                                if (!strongRegex.test(value)) return Promise.reject(string.signUpRuleMsg07);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type="password" className="input2"
                                        placeholder={string.passwordRule}
                                        style={{margin: 0}}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='formbox'>
                            <div className="login-field-icon">
                            <LockOutlined />
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="passwordConfirm"
                                    rules={[
                                        {
                                            required: true,
                                            message: string.signUpRuleMsg11
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(string.signUpRuleMsg05);
                                            },
                                        }),
                                    ]}
                                >
                                    <Input className="input2" type="password" placeholder={string.passwordConfirm}      style={{margin: 0}}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='formbox'>
                            <div className="login-field-icon">
                            <UserOutlined />
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: string.signUpRuleMsg12
                                        },
                                    ]}
                                >
                                    <Input className="input2" placeholder={string.name}      style={{margin: 0}}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='formbox'>
                            <div className="login-field-icon">
                            <PhoneOutlined />
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="phone"
                                    rules={[
                                        {
                                            required: true,
                                            message: string.signUpRuleMsg13
                                        },
                                    ]}
                                >
                                    <Input type="phone" className="input22" placeholder={string.inputTel} disabled={!phoneEditable}      style={{margin: 0}}/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className='formbox'>
                            <div className="login-field-icon">
                            <LockOutlined />
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="securityPassword"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || value.length != 4) return Promise.reject(string.signUpRuleMsg14);
                                                return Promise.resolve();
                                            },
                                        }),

                                    ]}
                                >
                                    <Input className="input2" type="number" placeholder={string.inputSecurity} maxLength={4} onInput={maxLengthCheck}     style={{margin: 0}} />

                                </Form.Item>
                            </div>
                        </div>
                        <div className='formbox'>
                            <div className="login-field-icon">
                            <LockOutlined />
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="securityPasswordConfirm"
                                    rules={[
                                        {
                                            required: true,
                                            message: string.signUpRuleMsg14
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('securityPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(string.signUpRuleMsg15);
                                            },
                                        }),
                                    ]}>
                                    <Input className="input2" type="number" placeholder={string.inputSecurityConfirm} maxLength={4} onInput={maxLengthCheck}     style={{margin: 0}} />
                                </Form.Item>
                            </div>
                        </div>
                        <div>
                            <div className="agree" >
                                {/* <div className="agree-link" onClick={this.agreementModal}>{string.policyView}</div> */}
                                <Form.Item name="useAgreement" valuePropName="checked" initialValue={false} className="chkbox" 
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value) return Promise.resolve();
                                                return Promise.reject(string.needAgreement);
                                            },
                                        }),
                                    ]}>
                                    <Checkbox className="agree-content" onChange={(e) => {
                                        if (e.target.checked) agreementModal()
                                    }}> {string.policyTitle2} </Checkbox>
                                </Form.Item>
                            </div>

                            <div>
                                <Form.Item>
                                    <Button className="signup-btn-ok" type="primary" htmlType="submit">{string.signUp}</Button>
                                </Form.Item>
                            </div>

                            <div style={{marginTop: 16}}>
                                {string.emailResendGuide1}<br/>
                                {string.emailResendGuide2}
                            </div>
                            <div style={{marginTop: 16}}>
                                <Form.Item>
                                    <Button className="resend-btn-ok" onClick={()=>setResendEmailVisible(true)}>{string.emailResend}</Button>
                                </Form.Item>
                            </div>
                        </div>                    
                    </Form>                
            </div>
            <Modal
                title={string.emailResend}
                open={resendEmailVisible}
                onOk={resendEmail}
                onCancel={()=> setResendEmailVisible(false)}
                okText={string.ok}
                cancelText={string.cancel}>
                <div style={{paddingBottom: '10px'}}>
                    {string.inputJoinEmail}
                </div>
                <div>
                    <Input className="input22" type="email"
                        placeholder={string.idEmail}
                        onChange={(e)=>setResendEmailAddress(e.target.value)}
                        value={resendEmailAddress}
                    />
                </div>
                <div style={{paddingBottom: '5px', color: 'red'}}>
                    {resendEmailError}
                </div>
            </Modal>
        </div >
    );
}


export default SignUp;