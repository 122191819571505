import { Form, Input, Checkbox, Button, Modal, Divider, Image } from 'antd';
import React, { useState } from 'react';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import { useSelector, useDispatch } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../actions";
import { Link, useNavigate } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import string from '../language/StringKo';
import Header from '../components/Header';
import mining from '../img/mining/mining-bg.png';
import Coin from '../lib/coin/coin';
import NumberFormat from '../lib/util/numberformat';

const FormItem = Form.Item;

const Mining = () => {
  const language = useSelector(state => state.stringReducer.language);
  const string = useSelector(state => state.stringReducer.string);   
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rewardToday, setRewardToday] = useState(0);
  const [currentReward, setCurrentReward] = useState(0);
  const [maxReward, setMaxReward] = useState(0);
  const [investAmount1, setInvestAmount1] = useState(0);
  const [investAmount2, setInvestAmount2] = useState(0);
  const [refundAmount1, setRefundAmount1] = useState(0);
  const [refundAmount2, setRefundAmount2] = useState(0);

  const getTodayReward = () => {
    httpGet(httpUrl.miningTodayReward, []).then((result) => {
      result = result.data;
      setRewardToday(result)
    });
  }
  const getTotalReward = () => {
    httpGet(httpUrl.miningTotalReward, []).then((result) => {
      result = result.data;
      setCurrentReward(result.currentReward);
      setMaxReward(result.maxReward);
    });
  }
  const getTotalInvest = () => {
    httpGet(httpUrl.miningTotalInvest, []).then((result) => {
      result = result.data;
      setInvestAmount1(result.amount1);
      setInvestAmount2(result.amount2);
    });
  }
  const getRefundAmount = () => {
    httpGet(httpUrl.miningRefundAmount, []).then((result) => {
      result = result.data;
      setRefundAmount1(result.amount1);
      setRefundAmount2(result.amount2);
    });
  }

  const logout = () => {
    Modal.confirm({
        title: string.myinfoLogout,
        content: string.myinfoLogoutConfirm,
        okText: string.ok,
        cancelText: string.cancel,
        onOk() {
            dispatch(setLogout());
            navigate('/');
        },
        onCancel() {
        },
    });
}
    // modal = () => {
    //   Navigation.showModal({
    //     component: {
    //       name: 'navigation.AccountDialog'
    //     },
    //   })
    // }

    // MiningCancelDialog = () => {
    //     Navigation.showModal({
    //         component: {
    //             name: 'navigation.MiningCancelDialog',
    //             passProps: {
    //                 title: string.MiningCancelTitle,
    //                 message1: string.MiningCancelsub1,
    //                 message2: string.MiningCancelsub2,
    //                 message3: string.MiningCancelsub3,
    //                 ok: this.props.logout,
    //             },
    //             options: {
    //                 topBar: { drawBehind: true, visible: false },
    //                 screenBackgroundColor: 'transparent',
    //                 modalPresentationStyle: 'overCurrentContext',
    //             },
    //         },
    //     });
    // };

    // Miningdetail = () => {
    //     Navigation.showModal({
    //         component: {
    //             name: 'navigation.MiningDetail'
    //         }
    //     })
    // }

    // MiningInvestment = () => {
    //     Navigation.showModal({
    //         component: {
    //             name: 'navigation.MiningInvestment'
    //         }
    //     })
    // }

    return (
        <div className="wrapper">
            <div className="BT-HD-right-container">
                <div className={`BT-HD-right-element ${language == 'ja' ? 'color-blue' : ''}`} onClick={logout}>
                    LOGOUT
                </div>
            </div>
            <div className="header">
                {/* {string.welcome} */}
            </div>

            <div className="container">
                <Header />

                <div className="container-box" style={{ paddingBottom: '30px' }}>

                  <div style={{textAlign: 'right'}}>
                    <Link to={"/miningDetail"}>
                    <Button style={{borderRadius: 10, width: 100, height: 40, marginBottom:20 ,fontWeight:'bold',color:'gray'}}>
                    {string.miningDetail}</Button>
                    </Link>
                    </div>
                    {/* <Button onPress={() => this.modal()}>
                <Text>{string.miningDetail}</Text>
                  </Button> */}

                    {/* 컨텐츠 */}

                    {/* 상단 이미지 부분 */}
                  <div className="mining-bg">              
                  
                      

                        <div style={{color:'white', fontSize:23, fontWeight:'bold', paddingTop:50 }}>
                            {string.miningToday}                              
                        </div>
                        <div className="wallet-titbox">
                        <div className="mining-logo"></div>
                        {(rewardToday  / Coin.getCoinUnit(203).base).toFixed(6)} {string.coinNameZZ}
                        </div>                            
                    </div>
                    {/* 중간 */}

                    <div style={{ flex: 1.2, paddingTop: 15, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                        <div style={{
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            padding: 20,
                            elevation: 1,
                            shadowColor: 'black',
                            shadowOffset: { width: 1, height: 1 },
                            shadowOpacity: 0.2,
                            shadowRadius: 0.5,
                        }} >
                            <div>
                                <div style={{ fontWeight:'500', display:'flex', justifyContent: 'space-between' }}>
                              <div>
                              {string.miningAccued}
                              </div>
                              <div>
                              {NumberFormat.commaNumber(currentReward)} / {NumberFormat.commaNumber(maxReward)}
                              </div>
                              </div>  
                              
                                
                              <div style={{color:'#5663ff', textAlign: 'center'}}> 
                                <Divider/>
                                {string.miningGiveInfo}                               
                            </div>
                          
                        </div>
                    </div>

                    {/* 하단 */}

                    <div style={{ flex: 2, paddingTop: 10, paddingHorizontal: 20, paddingBottom: 10, marginTop: 40 }}>
                        <div style={{
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            padding: 20,
                            elevation: 1,
                            shadowColor: 'black',
                            shadowOffset: { width: 1, height: 1 },
                            shadowOpacity: 0.2,
                            shadowRadius: 0.5,
                        }} >
                            {/* <Text style={{ fontSize: 16, fontWeight: '600', color: '#868686' }}>{string.miningInvestment}</Text> */}

                            <div style={{ flexDirection: 'row', paddingTop: 10, paddingBottom: 10, textAlign: 'left', color:'gray' ,fontSize:15}}>
                                {string.miningInvestment}                                
                            </div>
                            <div className="mining-myinvest" >
                                <div className="mining-myinvest-content1">
                                ZZ코인 <br />
                                이더리움
                                </div>
                                <div className="mining-myinvest-content2">
                                <Image width={50} height={25} preview={false} src={require('../img/mining/mining-arrow2.png')} /><br />
                                <Image width={50} height={25} preview={false} src={require('../img/mining/mining-arrow2.png')} />
                                </div>                                  
                                <div className="mining-myinvest-content3">
                                {(investAmount2 / Coin.getCoinUnit(203).base).toFixed(6)} {string.coinNameZZ}<br />
                                {(investAmount1 / Coin.getCoinUnit(2).base).toFixed(6)} {string.coinNameETH}
                                  </div>
                            </div>

                        </div>

                        <div style={{ height: 100, display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center',marginTop:50, textAlign:'center'}}>
                            <Button 
                                style={{ color:'white', paddingLeft: 50, paddingRight: 50, height: 80 , width:300, fontSize:22,
                                backgroundImage:"linear-gradient(to right, #43b4f8, #6a46ff)", borderRadius: 40, alignItems: 'center', justifyContent: 'center' }}>                                     
                                  {string.miningDoInvestment}
                            </Button>                             
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div >
    )
}


export default Mining;