import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector} from "react-redux";
const SignUpOk = () => {
    const string = useSelector(state => state.stringReducer.string);
    const navigate = useNavigate();
    const onClickLogin = () => {
        navigate('/')
    }
    return (
        <div className="wrapper">
            <div className="header">
                <div style={{paddingTop: 100}}>
                {string.welcome}
                </div>
            </div>
            <div align="center" className="signupok">
                <div className="signupok-text">
                {string.signUpMsg01}<br />
                {string.signUpMsg02}
                </div>
                <div className="signupok-button" onClick={()=>{onClickLogin()}}>
                    LOGIN
                </div>
            </div>
        </div>
    )
}

export default SignUpOk;