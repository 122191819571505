import { Form, Input, Checkbox, Button, Modal, Divider, Image } from 'antd';
import React from 'react';
import { useSelector } from "react-redux";
import Header from '../components/Header';

const MiningDetail = () => {
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);    

    const logout = () => {
        return;
    }
    return (
        <div className="wrapper">
            <div className="BT-HD-right-container">
                <div className={`BT-HD-right-element ${language == 'ja' ? 'color-blue' : ''}`} onClick={logout}>
                    LOGOUT
                </div>
            </div>
            <div className="header">
                {/* {string.welcome} */}
            </div>

            <div className="container">
                <Header />

                <div className="container-box" style={{ paddingBottom: '30px' }}>
    
                    {/* 컨텐츠 */}
                    dd
                    {/* 상단 이미지 부분 */}
                
                </div>
            </div>
        </div>
    )
}

export default MiningDetail;