import { Form, Input, Checkbox, Button, Modal, Table, Divider , Image} from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Header from '../components/Header';
import Top from './Top';
import { LeftCircleOutlined, } from '@ant-design/icons';
import InquiryDetaillModal from "../components/dialog/InquiryDetailModal";
import { reactLocalStorage } from "reactjs-localstorage";
import Const from '../const';

const Inquiry = () => {
    const navigate = useNavigate();
    const userinfo = reactLocalStorage.getObject(Const.appCode + "#userinfo"); 
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    });
    const [list, setList] = useState([]);
    const [inquiryModalVisible, setInquiryModalVisible] = useState(false);
    const [showContent, setShowContent] = useState(0);
    const [customPage, setCustompage] = useState([]);

    const handleTableChange = (page) => {
        setPagination({
            ...pagination,
            current : page
        })     
    };

    const getInquiryList = () => {
        let pageNum = pagination.current;
        let pageSize = pagination.pageSize;
        httpGet(httpUrl.inquiryList, [pageNum, pageSize], {})
            .then(res => {
                const page = { ...pagination }
                page.total = res.data.totalPage
                setList(res.data.list == null ? [] : res.data.list);
                setPagination(page)               
                let numArray = [];          
                for(let i=1; i<res.data.totalPage+1; i++) {                
                    numArray.push(i)               
                }
                setCustompage(numArray);
            })
    };

    useEffect(() => {
      getInquiryList();      
    },[pagination.current, inquiryModalVisible]);

    // requestBalance = () => {
    //     console.log("##### Request balance")
    //     httpGet(httpUrl.balance, [0], {}).then((result) => {
    //         result = result.data;
    //         console.log(result)
    //         this.setState({ balances: result })
    //     }).catch(e => {
    //         console.log("## requestBalance error: " + e)
    //     });
    // }
    // requestSystemListBlockchain = () => {
    //     console.log("## Loading systemListBlockchain");
    //     httpGet(httpUrl.systemListBlockchain, [this.props.language], {}).then((result) => {
    //         result = result.data;
    //         console.log('## systemListBlockchain loaded');
    //         this.setState({ blockchains: result })
    //     }).catch((e) => {
    //         console.log('## systemListBlockchain load error: ' + e)
    //     });
    // }
    const changeShowContent = (idx) => {
        if (showContent === idx) {
            setShowContent(0);
        } else
            setShowContent(idx);
    };

    const onClose = () => {
        setInquiryModalVisible(false);   
        getInquiryList();
    }

    return (
        <div className="wrapper">
            <Top />
            <div className="container" >
                <Header />
                <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                <div className="main-box">
                    <div className="main-title">
                        <div className="back-btn" onClick={()=>navigate(-1)}>
                            <LeftCircleOutlined style={{ fontSize: 24, color:'#fff' }} />
                        </div>
                        <div className="inquiry-btn">                           
                            <Button onClick={() => { setInquiryModalVisible(true) }}>
                                등록
                            </Button>
                        </div>
                        </div>
                    </div>
                    <div className="currencyNotice">
                        {inquiryModalVisible &&
                            <InquiryDetaillModal 
                            data={userinfo}
                            title="문의등록"
                            visible={inquiryModalVisible}
                            onClose={onClose}                           
                            />                        
                        }
                        {list.map((data)=>{
                            return(
                                <div className='noticeContainer'>
                                    <div className="notice-titleContainer" onClick={() => {changeShowContent(data.idx)}} >    
                                        <div className="noticeTag">    
                                            {data.answer === null ? <div className="inquiryTag1">질문</div> : <div className="inquiryTag2">답변완료</div>}                 
                                            <div className="inquiry-title"> {data.title} </div>
                                        </div>
                                        <div style={{ color: 'gray', fontWeight: 500 }}>{moment(data.createDate).format("MM/DD HH:mm")}</div>
                                    </div>                
                                {showContent === data.idx && data.answer !== null &&(
                                    <div className='inquiry-answer-container'>                            
                                    {data.answer.split("\n").map((line, index) =>{
                                        return (
                                            <div key={index} className='inquiry-answer'>    
                                                <div className='inquiry-answer-title'>                          
                                                    <Image width={15} height={15} preview={false} src={require('../img/right-arrow.png').default} />
                                                    <span style={{fontWeight:'bold',fontSize:14, color:'#fff'}}>관리자</span>                                          
                                                </div>                                            
                                                <div className="inquiry-answer-line">
                                                {line}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </div>                
                                )} 

                                </div>
                            )
                        })}                
                    </div>
                    <div className='custompage'>
                    {customPage.map((num)=>{
                        return(
                            <div 
                            onClick={()=>handleTableChange(num)}
                            className={num === pagination.current ? 'customPageNum Active':'customPageNum'}>
                                {num}
                            </div>
                        )
                    })}
                    </div>    
                </div>
            </div>
        </div>
    )
}

export default Inquiry;