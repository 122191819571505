import { Form, Input, Checkbox, Button, Modal, Table, Divider, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { useDispatch, useSelector } from "react-redux";
import { stringReducer, setLogin, setLogout, setCoinCurrency, setCoinList } from "../actions";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import string from '../language/StringKo';
import moment from "moment";
import { comma } from '../util/numberUtil';
import Header from '../components/Header';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


import Top from './Top';
const FormItem = Form.Item;

const Currency = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const coinList = useSelector(state => state.coinListReducer);   
    const language = useSelector(state => state.stringReducer.language);
    const [list, setList] = useState([]);
    const [balances, setBalances] = useState([]);
    const [blockchains, setBlockchains] = useState([]);
    const [refreshing, setRefreshing] = useState(false)
    const [coinCurrencyUpdateTime, setCoinCurrencyUpdateTime] = useState('');
    const [graphOpen, setGraphOpen] = useState(0);
    const [currencyLoaded, setCurrencyLoaded] = useState(false);
    let currency =  global.currency;
    let exchangeRate =  global.exchangeRate;

    const onClickCoinInfo = (coin) => {
        if (graphOpen == coin.coinType) setGraphOpen(0);
        else setGraphOpen(coin.coinType);
    }

    const requestCurrency = (hide) => {
        let target
        let check
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.currencies, [], {}, hide).then((result) => {
                // this.setState({ blockchains: this.state.blockchains},() => console.log("dddddddddddddddddddd " + JSON.stringify(this.state.blockchains)))
                dispatch(setCoinCurrency(result.data));
                setCoinCurrencyUpdateTime(new Date().toLocaleTimeString());
                setCurrencyLoaded(true);
                resolve();
            }).catch(e => {
                console.log("## requestCurrency error: " + e);
                reject(e);
            });
        })
    }

    const getNoticeList = () => {
        httpGet(httpUrl.noticeList, [1, 3], {})
            .then(res => {setList(res.data.noticeList)})
    }

    const handleRefresh = () => {
        setRefreshing(true)
        requestCurrency(true).then(() => {
            setRefreshing(false);
            setCoinCurrencyUpdateTime(new Date().toLocaleTimeString())

        }).catch(e => {
            setRefreshing(false)
            console.log("## requestCurrency error: " + e)
        });
    }

    useEffect(()=>{
       requestSystemListBlockchain();
        requestBalance();
        getNoticeList();
    },[])

    const requestBalance = () => {
        console.log("##### Request balance")
        // httpGet(httpUrl.balance, [0], {}).then((result) => {
        //     result = result.data;
        //     console.log(result)
        //     setBalances(result)
        // }).catch(e => {
        //     console.log("## requestBalance error: " + e)
        // });
    }
    const requestSystemListBlockchain = () => {
        console.log("## Loading systemListBlockchain");
        httpGet(httpUrl.systemListBlockchain, [language], {}).then(async (result) => {
            result = result.data;
            result.forEach(obj => {
                obj.status = true;
                obj.address = '';
                obj.balance = 0;
                obj.balanceLoaded = false;
                obj.price = 0;
                obj.priceLoaded = false;
                obj.value = 0;
                obj.valueLoaded = false;
                obj.purchaseFee = 0;
                obj.sendInFee = 0;
                obj.sendExFee = 0;
                obj.tradeFee = 0;
                obj.feeLoaded = false;
                obj.serverWallet = '';
                obj.serverWalletLoaded = false;
                obj.change = 0.0;
                obj.market = 0.0;
                obj.trends = [];
            })
            console.log('## systemListBlockchain loaded');
            dispatch(setCoinList(result));
            await requestCurrency();

        }).catch((e) => {
            console.log('## systemListBlockchain load error: ' + e)
        });
    }

    const  formatXAxis = (item) => {
        if (item === "logDate") return `${item.slice(0, 2)}`
        else return item
    };
    const formatYAxis = (item) => item.toLocaleString();
    const formatTooltip = (item) => item.toLocaleString();

    const columns = [

        {
            title: "",
            dataIndex: "idx",
            className: "column-center",
            render: (data) => <div style={{ color: 'gray', fontWeight: 500, textAlign: 'center' }}>{data}</div>

        },
        {
            title: "",
            dataIndex: "title",
            className: "column-center",
            render: (data) => <div className="notice-title2"> {data}</div >

        },
        {
            title: "",
            dataIndex: "createDate",
            className: "column-center noticeDate",
            render: (data) => <div style={{ color: 'gray', fontWeight: 500 }}>{moment(data).format("MM-DD")}</div>
        },

    ];

    return (
        <div className="wrapper">
            <Top />

            <div className="container">
                <Header />
                <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                    <div className="main-box">
                        <div className="main-title">
                            <div className="main-text">{string.notice}</div>                           
                            <Link to={"/notice"}>
                                <Button className='moreBtn'>{string.more}</Button>
                            </Link>                           
                        </div>
                        <div className='noticeTable'>
                        {list.map((item)=>{
                            return(
                                <div className='noticeTable-currency'>
                                    <div className='table-idx'>{item.idx}</div>
                                    <div className='table-title'>{item.title}</div>
                                    <div className='table-data'>{moment(item.data).format("MM-DD")}</div>
                                </div>
                            )
                            })
                        }
                        </div>
                        {/* <Table
                            className="noticeTable"
                            rowKey={(record) => record.idx}
                            columns={columns}
                            dataSource={list}
                            pagination={{ hideOnSinglePage: true }}
                        /> */}
                    </div>
                    <div className="main-box">
                        <div className="main-title cur-title">
                            <div className="main-text">{string.currency} </div>
                            <div className="sub-text">

                                {coinCurrencyUpdateTime} {string.updated} {string.criteriaCoinmarketcap}
                            </div>
                        </div>                     
                        {coinList.map((value, index, item, target) => {
                            let changeColor = '#000000';
                            let changeBackColor = '#ffffff';
                            let arrowImage = "";

                            if (value.change < 0) {
                                changeColor = '#f11015';
                                changeBackColor = '#ffffff';
                                arrowImage = require('../img/common/arrow_down.png').default;
                            }
                            if (value.change > 0) {
                                changeColor = '#0ab512';
                                changeBackColor = '#ffffff';
                                arrowImage = require('../img/common/arrow_up.png').default;
                            }

                            let balanceRow = balances.find(x => x.coinType == value.coinType);
                            let balance = 0;
                            if (balanceRow != null) balance = balanceRow.balance;
                            let sortedDate = value.trends.sort((a,b)=>a.logDate-b.logDate)
                            return (
                                <div className="blockchain-wrapper" key={index} onClick={() => { onClickCoinInfo(value) }}>
                                    <div className="blockchain-content" onClick={() => { onClickCoinInfo(value) }}>
                                        <div className='flex'>
                                            <div className="blockchain-image"><img src={value.image} onClick={() => { onClickCoinInfo(value) }} /></div>
                                            <div className="blockchain-content-name">{value.name}</div>
                                        </div>
                                        <div className="blockchain-content2">
                                            <div className="blockchain-content-balance">{comma(value.price)}
                                                {/* <span>{value.symbol}</span> */}<span>{string.krw}</span>
                                                {/* <span style={{ float: "right",fontSize:15 }}><Image src={arrowImage} width={10} />{value.change}%</span> */}
                                            </div>
                                            <div className="blockchain-content-balance2"><Image src={arrowImage} width={10} preview={false}/>{value.change}%</div>
                                        </div>
                                    </div>
                                    {graphOpen == value.coinType && value.trendY != null && (

                                        <div className="blockchain-graph">
                                            <ResponsiveContainer width="100%" aspect={3 / 1}>
                                                <LineChart width={400} height={200} data={sortedDate}>
                                                    <CartesianGrid strokeDasharray="8 8 8" />
                                                    <XAxis
                                                        dataKey={"logDate"}
                                                        tickFormatter={formatXAxis}
                                                    />
                                                    <YAxis
                                                        width={70}
                                                        tickFormatter={formatYAxis}
                                                        domain={['auto', 'auto']} />
                                                    <Tooltip
                                                    />
                                                    <Line
                                                        type="monotone"
                                                        dataKey={"price"}
                                                        stroke="#8884d8"
                                                        activeDot={{ r: 5 }}
                                                        dot={false}
                                                        isAnimationActive={true}
                                                    />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                    )}
                                </div>

                            );

                        })}
                    </div>
                </div>
            </div>

        </div>
    )
}


export default Currency;