import { Modal } from "antd";
import React, {} from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLogout } from "../actions";
import { useNavigate } from "react-router-dom";

const Top = () => {
  const language = useSelector(state => state.stringReducer.language);
  const string = useSelector(state => state.stringReducer.string);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    Modal.confirm({
        title: string.myinfoLogout,
        content: string.myinfoLogoutConfirm,
        okText: string.ok,
        cancelText: string.cancel,
        onOk() {
            dispatch(setLogout());
            navigate('/', {state: { logout: true}});
        },
        onCancel() {
        },
    });
  }

  const main = () => {
    navigate('/Currency');
  } 
  return(
    <>
      <div className="BT-HD-right-container">
      <img src={require('../img/logo/logo.png').default} style={{width: 160}}/> 
      <div style={{marginLeft:'auto'}}>
        <div className={`BT-HD-right-element ${language == 'ja' ? 'color-blue' : ''}`} onClick={logout}>
            LOGOUT
        </div>
        </div>
      </div>
      <div className="header" onClick={main}>
      </div>
    </>
  )
}

export default Top;