import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React, { useState } from 'react';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { useSelector, useDispatch } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../actions";
import { useNavigate } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import string from '../language/StringKo';
import Header from '../components/Header';
import Top from './Top';
const FormItem = Form.Item;

const Exchange = () => {
    const coinList = useSelector(state => state.coinListReducer);   
    const [selectedCoinType, setSelectedCoinType] = useState(this.props.selectedCoinType);
    const [tableData, setTableData] = useState([]);
    const [isShowCoinSelect, setIsShowCoinSelect] = useState(false);
    const [active, setActive] = useState(0);
    let currency = global.currency;
    let exchangeRate = global.exchangeRate;
    let currenctPageNum = 1;
    let pageSize = 10;
    let latestPageNum = 1;
    let requesting = false;   

    const setOnActive = (idx) => {
        setTableData([])
        setIsShowCoinSelect(false)
        setActive(idx);     
        initialLoadActive();     
    }

    const addCoin = (coinType) => {
        httpPost(httpUrl.addCoin, [], { coinType: coinType })
            .then(result => {
                console.log('addCoin result: ' + JSON.stringify(result));
                result = result.data;
                console.log('## addCoin success');
            })
            .catch(e => {
                console.log('## addCoin error: ' + e);
            });
    }

    const calTotalValue = () => {
        let totalValue = 0;
        let allLoaded = true;
        coinList.forEach(coin => {
            allLoaded &= coin.valueLoaded;
            totalValue += coin.value;
        });
        // if (allLoaded) return totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // else return '-';
        // return (totalValue * this.state.exchangeRate[this.state.currency]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (totalValue * exchangeRate[currency]).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    // toggleCoinSelect = () => {
    //     console.log('toggleCoinSelect..');
    //     Navigation.showModal({
    //         component: {
    //             name: 'navigation.CoinSelectDialog',
    //             passProps: {
    //                 coinList: this.props.coinList,
    //                 onChangeSelectedCoinType: this.onChangeSelectedCoinType,
    //                 selectedCoinType: this.state.selectedCoinType,
    //             },
    //             options: {
    //                 topBar: { drawBehind: true, visible: false },
    //                 screenBackgroundColor: 'transparent',
    //                 modalPresentationStyle: 'overCurrentCondiv',
    //             },
    //         },
    //     });
    // }

    const initialLoadActive = () => {
        setTableData([]);

        currenctPageNum = 1;
        pageSize = 10;
        latestPageNum = 1;

        this.props.requestBalance(selectedCoinType);

        // if (active == 0) getTradeList();
        // else if (active == 1) getExchangeList();
        // else if (active == 2) getPayList();
    }

    // const scrollLoadActive = () => {
    //     if (active == 0) getTradeList();
    //     else if (active == 1) getExchangeList();
    //     else if (active == 2) getPayList();
    // }

    const onChangeSelectedCoinType = (idx) => {
        setSelectedCoinType(idx);
        initialLoadActive();      
    };
    return (
        <div className="wrapper">
            <Top />
            <div className="container">
                <Header />
                <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                    <div className="main-box">
                        <div className="main-title">
                            <div className="main-text">공지사항</div>
                            <div className="more-btn">
                            </div>
                        </div>
                        <div className="currencyNotice">

                        </div>
                    </div>
                    <div className="" >

                        {/* 사용코인 */}

                        <div style={{
                            height: 100,
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            padding: 20
                        }}>
                            <div>
                                {/* {string.exchangeUseCoin} */}
                            </div>

                            <div style={{ marginVertical: 5 }}>

                                <div style={{ flexDirection: 'row', alignItems: 'center' }}>

                                    <div style={{ flex: 1 }}>
                                        <Button
                                            style={{ flexDirection: 'row', alignItems: 'center' }}
                                            // onPress={toggleCoinSelect}
                                            >

                                            <div
                                                style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                {/* <ScalableImage
                                                    source={require('../img/exchange/zz.png')}
                                                    width={30} /> */}

                                            </div>

                                            {/* <div style={{ marginLeft: 10, width: 60, divAlign: 'center', fontSize: 14.7 }}>{string.coinNameZZ} {string.coin}</div> */}

                                            <div style={{ justifyContent: 'center', marginLeft: 5 }}>
                                                {/* <Image source={require('../img/common/dropdown_arrow.png')} /> */}
                                            </div>

                                        </Button>
                                    </div>

                                    <div style={{ width: 160, height: 40, marginLeft: 15, flex: 1.5 }}>
                                        {/* <TextInput
                                            style={{ borderWidth: 1, borderColor: color.lightgray, borderRadius: 10, paddingLeft: 10 }}
                                            placeholder={string.miningInputAmount}
                                            keyboardType="numeric"
                                        /> */}
                                    </div>
                                </div>

                            </div>

                        </div>


                        {/* 받는코인 */}

                        <div style={{
                            height: 100,
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            elevation: 1,
                            shadowColor: 'black',
                            shadowOffset: { width: 1, height: 1 },
                            shadowOpacity: 0.2,
                            shadowRadius: 0.5,
                            padding: 20,
                            marginTop: 10
                        }}>
                            <div>
                                {/* <div style={{ paddingLeft: 5, color: '#686868' }}>{string.exchangeReceiveCoin}</div> */}
                            </div>

                            <div style={{ marginVertical: 5 }}>

                                <div style={{ flexDirection: 'row', alignItems: 'center' }}>

                                    <div style={{ flex: 1 }}>
                                        <Button
                                            style={{ flexDirection: 'row', alignItems: 'center' }}
                                            // onPress={toggleCoinSelect}
                                            >

                                            <div
                                                style={{
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                {/* <ScalableImage
                                                    source={require('../img/exchange/eth-logo.png')}
                                                    width={30} /> */}
                                            </div>

                                            {/* <div style={{ marginLeft: 10, width: 60, divAlign: 'center', fontSize: 14.7 }}>{string.coinNameETH} {string.coin}</div> */}

                                            <div style={{ justifyContent: 'center', marginLeft: 5 }}>
                                                {/* <Image source={require('../img/common/dropdown_arrow.png')} /> */}
                                            </div>

                                        </Button>
                                    </div>

                                    <div style={{ width: 160, height: 40, marginLeft: 15, flex: 1.5 }}>
                                        {/* <TextInput
                                        style={{ borderWidth: 1, borderColor: color.lightgray, borderRadius: 10, paddingLeft: 10, }}
                                        placeholder={string.miningInputAmount}
                                        keyboardType="numeric"
                                    /> */}
                                    </div>
                                </div>
                            </div>

                        </div>



                        <div style={{ flex: 1.5, width: '100%', paddingHorizontal: 20, marginTop: 10 }}>

                            <div style={{ flexDirection: 'row', marginVertical: 10 }}>
                                {/* <div style={{ width: 60 }}>{string.exchangeRate}</div>
                            <div style={{}}>{string.RateZZtoETH}</div> */}
                            </div>

                            <div style={{ flexDirection: 'row' }}>
                                {/* <div style={{ width: 60 }}>{string.fee}</div>
                            <div style={{}}>{string.feeOneday}</div> */}
                            </div>

                        </div>
                    </div>



                    <div>


                        <Button>

                            {/* {string.exchangeCoinConfirmDlgTtl} */}

                        </Button>



                    </div>

                </div>

            </div>
        </div >
    )
}

export default Exchange;