import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { httpUrl, httpPost } from "../api/httpClient";
import crypto from 'crypto';
import { stringReducer, setLogin } from "../actions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SmileOutlined, LockOutlined } from '@ant-design/icons';
import { reactLocalStorage } from "reactjs-localstorage";
import Const from '../const';

const Login = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const logout = location.state ? location.state.logout : false;
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);    
    const navigate = useNavigate();
    const [user, setUser] = useState({
        id: '',
        password: '',
    });
    const [loginSetting, setLoginSetting] = useState({
        savedId:false,
        autoLogin:false
    });
    useEffect(() => {    
        const loginSetting = reactLocalStorage.getObject(Const.appCode + "#loginSetting");  
        if (loginSetting) {
            const userData = reactLocalStorage.getObject(Const.appCode + "#user");          
            if (loginSetting.savedId) {        
                setUser({id: userData.id});
                setLoginSetting({ savedId:true, autoLogin:false })
            } else if (loginSetting.autoLogin) {
                setUser({id: userData.id});
                setLoginSetting({ savedId:false, autoLogin:true});       
                if (!logout) { autoLogin(userData)};
            };
        }
    }, []);

    const autoLogin = async (userData) => {
        try {
            const res = await httpPost(httpUrl.login, [], userData);
            resultFuc(res);
        // if (res.data.result) {
        //     dispatch(setLogin(res.data.user));
        //     reactLocalStorage.setObject(Const.appCode + "#userinfo", res.data.user);                
        //     if (loginSetting.savedId || loginSetting.autoLogin) {
        //         reactLocalStorage.setObject(Const.appCode + "#user", user);
        //         reactLocalStorage.setObject(Const.appCode + "#loginSetting", loginSetting);
        //     }              
        //     navigate('/Currency');
        // } else { loginError(res.data) };
        } catch(e){
            Modal.info({
                title: string.login,
                content: string.loginFailedMessage,
                onOk() { },
            });  
        }
    };

    const resultFuc = (res) => {
        const result = res.data;
        if (result.result) {
            dispatch(setLogin(result.user));
            reactLocalStorage.setObject(Const.appCode + "#userinfo",result.user);                
            if (loginSetting.savedId || loginSetting.autoLogin) {
                reactLocalStorage.setObject(Const.appCode + "#user", user);
                reactLocalStorage.setObject(Const.appCode + "#loginSetting", loginSetting);
            }             
            else {
                reactLocalStorage.remove(Const.appCode + "#user");
                reactLocalStorage.remove(Const.appCode + "#loginSetting");
            } 
            navigate('/Currency');
        } else {
            let message = string.loginFailedMessage;
            if (result.reason == 'INVALID_LOGIN_ID') message = string.loginFailedWrongId;
            else if (result.reason == 'INVALID_LOGIN_PASSWORD') message = string.loginFailedWrongPassword;
            else if (result.reason == 'INVALID_LOGIN_INFO') message = string.loginFailedInvalidLoginInfo;
            else if (result.reason == 'EXPIRED') message = string.loginFailedExpiredId;
            else if (result.reason == 'EMAIL_CONFIRM_NOT_YET') message = string.signUpMsg03;
            Modal.info({
                title: string.loginFailedTitle,
                content: message,
                onOk() { },
            });
        }
    };
    
    const handleSubmit = () => {
        let users = user;
        if (users.id === '' || users.id === undefined) {
            alert(string.signUpRuleMsg09);
            return;
        }
        if (user.password === '' || user.password === undefined) {
            alert(string.signUpRuleMsg10);
            return;
        }   
        let password = user.password + user.id;
        let passwordHash = crypto
            .createHash('sha256')
            .update(password, 'utf8')
            .digest()
            .toString('hex');
        users.id = user.id;
        users.password = passwordHash;
 
        httpPost(httpUrl.login, [], users).then((result) => {
            console.log("## login result: " + JSON.stringify(result, null, 4));
            resultFuc(result);
            // result = result.data;
            // if (result.result) {
            //     console.log("## login success");
            //     dispatch(setLogin(result.user));
            //     reactLocalStorage.setObject(Const.appCode + "#userinfo", result.user);                
            //     if (loginSetting.savedId || loginSetting.autoLogin) {
            //         reactLocalStorage.setObject(Const.appCode + "#user", user);
            //         reactLocalStorage.setObject(Const.appCode + "#loginSetting", loginSetting);
            //     }              
            //     navigate('/Currency');
            // } else { loginError(result) }
        }).catch(e => {
            console.log('## signUp error: ' + e);
            Modal.info({
                title: string.login,
                content: string.loginFailedMessage,
                onOk() { },
            });           
        });
    };

    const onChange = (e) => {
        console.log(e)
        setLoginSetting({
            savedId:false,
            autoLogin:false
        })
        const { checked, id } = e.target;
        setLoginSetting({
            [id]: checked
        })
    };

    return (
        <div className="wrapper">
            <div className="BT-HD-right-container">
                <img src={require('../img/logo/logo.png').default} style={{width: 160}}/> 
                <div style={{marginLeft: 16}}>
                    <div className={`BT-HD-right-element ${language == 'ko' ? 'color-blue' : ''}`} onClick={() => dispatch(stringReducer('ko'))}>
                        KO
                    </div>
                    <div className={`BT-HD-right-element ${language == 'en' ? 'color-blue' : ''}`} onClick={() => dispatch(stringReducer('en'))}>
                        EN
                    </div>
                    <div className={`BT-HD-right-element ${language == 'ja' ? 'color-blue' : ''}`} onClick={() => dispatch(stringReducer('ja'))}>
                        JA
                    </div>
                </div>
            </div>           
            <div className="container-login-box" style={{ textAlign: 'center' }}>
                <div className="header">
                    {string.welcome}
                </div>
                <div className="form login">
                    <div className='formbox'>
                        <div className="login-field-icon">
                            <SmileOutlined/>
                        </div>
                        <div className="login-field-input">                          
                            <Input className="input22"
                                autoComplete='false'
                                placeholder={string.idEmail}
                                style={{margin: 0}}
                                value={user.id}
                                onChange={(e)=>setUser({...user, id: e.target.value.trim()})}
                            />                          
                        </div>
                    </div>
                    <div className='formbox'>

                        <div className="login-field-icon">
                            <LockOutlined />
                        </div>
                        <div className="login-field-input">                           
                            <Input type="password" className="input2"
                            autoComplete='off'
                                placeholder={string.password}
                                style={{margin: 0}}
                                onChange={(e)=>setUser({...user, password: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className='loginsetting'>
                        <Checkbox id="savedId" checked={loginSetting.savedId} onChange={onChange}>{string.savedId}</Checkbox>
                        <Checkbox id="autoLogin" checked={loginSetting.autoLogin} onChange={onChange}>{string.autoLogin}</Checkbox>
                    </div>
                    <div>
                        <div className="signupbtn" onClick={handleSubmit}>                       
                            <Button className="signup-btn-ok" type="primary" htmlType="submit">{string.login}</Button>                      
                        </div>                       
                        <div className="signup-link" onClick={() => navigate('/signup')}>
                            {string.signUp}
                        </div>
                    </div>
                    <div className="findpw-link" onClick={() => navigate('/modifyPassword')}>
                        {string.findPw}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;