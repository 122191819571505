import { Modal, Table, Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl,} from "../api/httpClient";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../actions";
import moment from "moment";
import Header from '../components/Header';
import Top from './Top';
import { LeftCircleOutlined, } from '@ant-design/icons';

const Notice = () => {
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    });
    const [list, setList] = useState([]);
    const [showContent, setShowContent] = useState(0);
    const [balances, setBalances] = useState([]);
    const [blockchains, setBlockchains] = useState([]);
    const [customPage, setCustompage] = useState([]);

    useEffect(()=>{
        getNoticeList();
        console.log("노틱스")
    },[pagination.current]);

    const handleTableChange = (page) => {   
        setPagination({
            ...pagination,
            current : page
        })      
    };

    const getNoticeList = () => {
        let pageNum = pagination.current;
        let pageSize = pagination.pageSize;
        httpGet(httpUrl.noticeList, [pageNum, pageSize], {})
        .then( res => {
            console.log(res)
            const page = { ...pagination }
            page.total = res.data.totalPage;
            setList(res.data.noticeList)
            setPagination(page)     
            let numArray = [];          
            for(let i=1; i<res.data.totalPage+1; i++) {                
                numArray.push(i)               
            }
            setCustompage(numArray);
        })
    }

    const requestBalance = () => {
        console.log("##### Request balance")
        httpGet(httpUrl.balance, [0], {}).then((result) => {
            result = result.data;
            console.log(result)
            setBalances(result);
        }).catch(e => {
            console.log("## requestBalance error: " + e)
        });
    };

    const requestSystemListBlockchain = () => {
        console.log("## Loading systemListBlockchain");
        httpGet(httpUrl.systemListBlockchain, [language], {}).then((result) => {
            result = result.data;
            console.log('## systemListBlockchain loaded');
            setBlockchains(result)
        }).catch((e) => {
            console.log('## systemListBlockchain load error: ' + e)
        });
    };

    const changeShowContent = (idx) => {
        if (showContent === idx) {
            setShowContent(0);           
        } else setShowContent(idx);      
    };

    return (
        <div className="wrapper">
            <Top />
            <div className="container" >
                <Header />
                <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                    <div className="main-box">
                        <div className="main-title">
                            <div className="back-btn" onClick={()=>navigate(-1)}>
                                <LeftCircleOutlined style={{ fontSize: 24, color:'#fff' }} />
                            </div>
                        </div>
                    </div>
                    <div className="currencyNotice">
                        {list.map((item)=>{
                            return(                  
                            <div className='noticeContainer'>
                                <div className='notice-titleContainer'>
                                    <div className="noticeTag" onClick={() => {changeShowContent(item.idx)}} >                                     
                                        {item.category === "NEWS" ?
                                            <div className="noticeTag1">{string.news}</div> 
                                            : item.category === "NOTE" ?
                                            <div className="noticeTag2">{string.note1}</div> 
                                            : ""
                                        }
                                        <div className="notice-title">{item.title}</div>  
                                    </div>                              
                                    <div style={{ color: 'gray', fontWeight: 500 }}>{moment(item.createDate).format("YYYY-MM-DD")}</div>
                                </div>
                                {showContent === item.idx && (
                                    <div className="noticeContent">
                                        {item.content.split("\n").map((line, index) => {
                                            return (
                                                <span key={index}>
                                                    {line}
                                                    <br />
                                                </span>
                                            )
                                        })}
                                    </div>
                                )}                               
                            </div>  
                            )
                        })}                      
                    </div>
                    <div className='custompage'>
                    {customPage.map((num)=>{
                        return(
                            <div 
                            onClick={()=>handleTableChange(num)}
                            className={num === pagination.current ? 'customPageNum Active':'customPageNum'}>
                                {num}
                            </div>
                        )
                    })}
                    </div>                   
                </div>
            </div>
        </div>
    )
}

export default Notice;